<template>
  <div class="hs-dropdown relative w-full [--placement:top] border-t border-gray-200 cursor-pointer">
    <div
      id="hs-dropdown-with-title"
      :class="isCollapse ? 'justify-center' : 'delay-300'"
      class="flex-row hs-dropdown-toggle w-full py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
      data-cy-menu-dropdown-user
    >
      <div class="bg-gray-200 p-2 rounded-full w-9">
        <img src="/img/icon-user.svg" alt="Icone utilisateur" />
      </div>
      <div class="ps-2.5 overflow-hidden" :class="isCollapse ? 'invisible absolute' : 'delay-300'">
        <div class="font-bold truncate ...">{{ menuDropdownDto.currentItem.transportOrganizerName }}</div>
        <div>{{ menuDropdownDto.email }}</div>
      </div>
      <div class="flex items-center w-16 justify-end" :class="isCollapse ? 'invisible absolute' : 'delay-300'">
        <svg class="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 10 14" fill="none">
          <path
            d="M9.13856 5.0287L5.00049 0.890625L0.862405 5.0287L1.80521 5.9715L5.00049 2.77625L8.19576 5.9715L9.13856 5.0287ZM0.862305 8.97163L5.00036 13.1097L9.13843 8.97163L8.19563 8.02883L5.00036 11.2241L1.80511 8.02883L0.862305 8.97163Z"
            fill="#6B7280"
          />
        </svg>
      </div>
    </div>

    <div
      class="border-gray-200 border hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 divide-y divide-gray-200"
      aria-labelledby="hs-dropdown-with-title"
    >
      <template v-if="menuDropdownDto.items.length > 1">
        <div class="py-2 first:pt-0 last:pb-0" data-cy-menu-dropdown-user-select-transport-organizer>
          <template v-for="(item, index) in menuDropdownDto.items" :key="index">
            <a
              class="max-w-80 flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 focus:outline-none focus:bg-gray-100"
              href="#"
              :class="item.id == menuDropdownDto.currentItem.id ? 'font-bold cursor-text' : 'hover:bg-gray-100 cursor-pointer'"
              :data-cy-menu-dropdown-user-select-transport-organizer-choice="item.transportOrganizerName"
              @click="
                () => {
                  setCurrentTransportOrganizer(item.id);
                }
              "
            >
              <div class="bg-gray-200 p-2 rounded-full w-8">
                <img src="/img/icon-user.svg" alt="Icone utilisateur" />
              </div>
              <BaseTruncateText :content="item.transportOrganizerName" />
              <div v-if="item.id === menuDropdownDto.currentItem.id" class="grow flex flex-row justify-end">
                <span class="icon-check-mark" />
              </div>
            </a>
          </template>
        </div>
      </template>
      <div class="py-2 first:pt-0 last:pb-0">
        <a
          href="#"
          class="text-red-500 flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700"
          data-cy-menu-dropdown-user-logout
          @click.prevent="logout"
        >
          Se déconnecter
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useMenuStore } from '~/stores/common/MenuStore';
  import { useAuthStore } from '~/stores/api/AuthStore';
  import { useTransportOrganizerStore } from '~/stores/api/TransportOrganizerStore';
  import type MenuDropdownDTO from '~/dto/common/menu/MenuDropdownDTO';
  import { useUserStore } from '~/stores/api/UserStore';
  import type TransportOrganizerResource from '~/api/resource/TransportOrganizerResource';
  import { useGlobalLoadingStore } from '~/stores/common/GlobalLoadingStore';
  import BaseTruncateText from '~/components/ui/BaseTruncateText.vue';

  const transportOrganizerStore = useTransportOrganizerStore();
  const { setLoading } = useGlobalLoadingStore();
  const authStore = useAuthStore();
  const { isCollapse } = storeToRefs(useMenuStore());
  const { user } = storeToRefs(useUserStore());

  defineProps<{ menuDropdownDto: MenuDropdownDTO }>();

  const setCurrentTransportOrganizer = async (id: number) => {
    setLoading(true);
    const transportOrganizers = user?.value?.transportOrganizers as TransportOrganizerResource[];
    const newCurrentTransportOrganizer = transportOrganizers.find((transportOrganizer) => transportOrganizer.id === id);

    if (!newCurrentTransportOrganizer) {
      setLoading(false);
      throw Error('Cannot find current transport organizer');
    }

    await transportOrganizerStore.set(newCurrentTransportOrganizer);
    reloadNuxtApp({
      force: true
    });
  };

  const logout = async () => {
    await authStore.logout();

    navigateTo('/login');
  };
</script>
