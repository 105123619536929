<template>
  <div
    id="docs-sidebar"
    :class="isCollapse ? 'w-20 overflow-visible' : 'w-64'"
    class="hs-overlay [--auto-close:lg] hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed top-0 start-0 bottom-0 z-[60] bg-white border-e border-gray-200 pt-6 lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300"
  >
    <div class="flex flex-col h-full">
      <div class="flex" :class="isCollapse ? 'flex-col ps-[30px]' : 'justify-between flex-row pb-4 ps-[30px] px-4 items-center'">
        <a class="flex-none text-xl font-semibold dark:text-white" href="#" aria-label="Brand">
          <img v-if="!isCollapse" src="/img/logo-primary-horizontal.svg" alt="Logo nomad" />
          <img v-if="isCollapse" src="/img/logo-collapse.svg" alt="Logo nomad" :class="isCollapse ? 'pb-2' : ''" />
        </a>
        <button class="button button-icon-md button-dark button-ghost w-fit" :class="isCollapse ? '-ms-[8px]' : ''" @click="toggleCollapse">
          <OptiagoIcon class="fill-gray-800" name="optiago:panel-icon" />
        </button>
      </div>
      <nav class="hs-accordion-group p-4 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
        <ul class="space-y-1.5">
          <MenuItem
            v-for="route in routes"
            :key="route.name"
            :route="route.route"
            :label="route.label"
            :is-active="route.activeNames.includes(currentRoute.name.toString())"
            :icon="route.icon"
          />
        </ul>
      </nav>

      <div class="grow flex flex-col justify-end">
        <MenuDropdown v-if="menuDropdownDTO" :menu-dropdown-dto="menuDropdownDTO" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useMenuStore } from '~/stores/common/MenuStore';
  import MenuDropdown from '~/components/common/menu/MenuDropdown.vue';
  import { toDTO } from '~/services/common/menu/MenuDropdownMapper';
  import { useUserStore } from '~/stores/api/UserStore';
  import { useTransportOrganizerStore } from '~/stores/api/TransportOrganizerStore';
  import { DateTime } from 'luxon';
  import OptiagoIcon from '~/components/ui/OptiagoIcon.vue';

  const { isCollapse } = storeToRefs(useMenuStore());
  const { toggleCollapse } = useMenuStore();
  const { currentRoute } = useRouter();
  const { user } = storeToRefs(useUserStore());
  const { currentTransportOrganizer } = storeToRefs(useTransportOrganizerStore());
  const menuDropdownDTO = computed(() => (user.value && currentTransportOrganizer.value ? toDTO(user.value, currentTransportOrganizer.value) : null));

  const defaultWeekNumber = getWeekNumber(DateTime.now());

  const routes = [
    { route: '/structures', label: 'Structure', name: 'structures', icon: 'building', activeNames: ['structures', 'structures-id', 'structures-create'] },
    {
      route: '/passengers',
      label: 'Usagers',
      name: 'passengers',
      icon: 'team',
      activeNames: [
        'passengers',
        'passengers-id',
        'passengers-create',
        'passengers-id-edit',
        'passengers-id-transport-requests-create',
        'passengers-id-transport-requests-transportrequestid',
        'passengers-id-addresses-create',
        'passengers-id-addresses-addressid'
      ]
    },
    { route: '/transport-requests', label: 'Trajets', name: 'transport-request', icon: 'guide', activeNames: ['transport-requests'] },
    { route: '/vehicles', label: 'Véhicules', name: 'vehicles', icon: 'bus', activeNames: ['vehicles', 'vehicles-id', 'vehicles-create'] },
    {
      route: '/planification?week=' + defaultWeekNumber + '&filter=1',
      label: 'Planification',
      name: 'planification',
      icon: 'calendar',
      activeNames: ['planification', 'itineraries-days']
    },
    {
      route: '/grouping-points',
      label: 'Point de rendez-vous',
      name: 'grouping-point',
      icon: 'map-pin',
      activeNames: ['grouping-points', 'grouping-points-id', 'grouping-points-create']
    },
    { route: '/parkings', label: 'Parking', name: 'parkings', icon: 'parking', activeNames: ['parkings', 'parkings-id', 'parkings-create'] },
    { route: '/parameters', label: 'Paramètres', name: 'parameters', icon: 'gear', activeNames: ['parameters'] }
  ];
</script>
